import {
  size,
  flex,
  marginChildren,
  position,
  fontSize,
  laptop,
  flexGap,
  mobile,
  mobileLandscape,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

import { elevate_s, elevate_xxl, theme } from '@faxi/web-component-library';

const TemplateGrid = styled.div`
  overflow: auto;
  display: grid;
  grid-template-areas: 'sidebar main-footer';
  grid-template-columns: auto 1fr;

  ${phablet(css`
    grid-template-areas: 'sidebar' 'main-footer';
    grid-template-columns: unset;
    grid-template-rows: ${theme.sizes.SIZE_80} 1fr auto;
    transition: 300ms;

    .wcl-sidebar__header {
      height: 100%;
      transition: transform 200ms ease-in-out;
    }

    &.scrolling-down {
      grid-template-rows: 0px 1fr auto;

      .wcl-sidebar__header {
        transform: translate3d(0, -100%, 0);
      }
    }
  `)};

  .main-template {
    &__sidebar {
      z-index: 2;
      grid-area: sidebar;

      &__expanded-onboarding-status {
        width: 100%;
        padding: 0 ${theme.sizes.SIZE_16};
      }
    }

    &__content-wrapper {
      ${flex('column')};

      overflow-y: auto;
      overflow-x: hidden;
      grid-area: main-footer;

      > main {
        flex: 1 0 100%;
      }

      ${phablet(css`
        overscroll-behavior: none;
      `)}
    }
  }
`;

const AuthGrid = styled.div`
  ${size('auto', '100%')};

  h1 {
    color: var(--PRIMARY_1_1);
    margin-bottom: ${theme.sizes.SIZE_24};
  }

  ${phablet(css`
    padding: ${`${theme.sizes.SIZE_46} ${theme.sizes.SIZE_16}`};

    h1 {
      text-align: center;
      word-break: break-word;
    }
  `)};

  .form {
    max-width: ${theme.sizes.SIZE_520};
    margin: 0 auto ${theme.sizes.SIZE_64};

    &__fields {
      ${flex('column', 'center', 'flex-start')};

      padding: ${theme.sizes.SIZE_40};
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);

      ${phablet(css`
        width: 100%;
        padding: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_32}
          ${theme.sizes.SIZE_64}`};
      `)};

      &__field {
        width: 100%;
        margin-bottom: ${theme.sizes.SIZE_24};
      }

      > :nth-last-child(2) {
        margin-bottom: 0;
      }

      &__commands {
        ${flex('column', 'flex-start', 'center')};
        width: 100%;
        margin-top: ${theme.sizes.SIZE_32};

        &--standard {
          &,
          .login-btn {
            width: 100%;
          }

          .reset-password-btn,
          .return-login-btn {
            ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_16)};

            margin: 0 auto;
            font-weight: 400;
            margin-top: ${pxToRem(theme.sizes.SIZE_20)};
          }
        }

        &--or {
          position: relative;
          width: 100%;
          margin: ${theme.sizes.SIZE_32} 0;
          min-height: ${pxToRem(theme.sizes.SIZE_32)};

          span {
            ${position('absolute', 'top 0 left 50%')};
            ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

            font-weight: 400;
            color: var(--SHADE_1_1);
            transform: translate3d(-50%, 0, 0);
            background: var(--BACKGROUND_1_1);
            padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8};
          }

          &::before {
            display: flex;
            ${position('absolute', 'top 50% left 0')};
            ${size('100%', '1px')};

            content: '';
            background-color: var(--SHADE_1_5);
          }
        }

        &--social {
          ${flex('row', 'center', 'center')};
          ${flexGap(theme.sizes.SIZE_16, 'row')};
          width: 100%;
        }

        &__command {
          &--full-width-mobile {
            ${mobile(css`
              width: 100%;
            `)};
          }
        }

        ${mobile(css`
          flex-direction: column;
          align-items: flex-start;
          ${marginChildren(`0 0 ${theme.sizes.SIZE_40} 0`)};
        `)};
      }

      &__switch {
        ${flex('row', 'space-between')};

        width: 100%;

        margin-bottom: ${theme.sizes.SIZE_16};

        .wcl-switch__label {
          font-size: ${theme.fontSizes.FONT_16};
        }
        a {
          color: var(--PRIMARY_1_1);
        }
      }

      &__terms {
        width: 100%;
        margin-top: ${theme.sizes.SIZE_8};
        padding-right: ${theme.sizes.SIZE_16};
      }
    }

    &__input {
      width: ${pxToRem(theme.sizes.SIZE_376)};

      ${phablet(css`
        width: 100%;
      `)};

      &-email {
        margin-bottom: ${theme.sizes.SIZE_32};
      }

      &-password {
        margin-bottom: ${theme.sizes.SIZE_24};
      }
    }

    ${phablet(css`
      margin-top: 0px;
    `)}

    &__redirect-link {
      margin-top: ${theme.sizes.SIZE_48};

      color: var(--SHADE_1_2);

      a {
        text-decoration: underline;
        color: var(--PRIMARY_1_1);
      }
    }
  }

  &.kinto-auth-grid {
    padding: ${theme.sizes.SIZE_40};
  }

  .kinto-auth-grid {
    &__login-successful-header {
      ${fontSize(theme.fontSizes.FONT_22)};

      font-weight: 500;
      color: var(--PRIMARY_1_1);
      margin-top: ${theme.sizes.SIZE_46};
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__login-successful-body {
      ${fontSize(theme.fontSizes.FONT_18)};

      font-weight: 500;
      color: var(--SHADE_1_2);
      margin-bottom: ${theme.sizes.SIZE_72};
    }
  }
`;

interface DropzoneFormProps {
  visible?: boolean;
}

export const SettingsForm = styled.div<DropzoneFormProps>`
  ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};

  position: relative;
  max-width: ${pxToRem(theme.sizes.SIZE_1096)};

  .kinto-dropzone-form {
    ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};

    margin-bottom: ${theme.sizes.SIZE_32};

    &__title {
      ${fontSize(theme.fontSizes.FONT_14)};

      color: var(--PRIMARY_1_1);
      border-bottom: ${theme.sizes.SIZE_2} solid var(--PRIMARY_1_1);
      padding-bottom: ${theme.sizes.SIZE_8};
      font-weight: 600;
      width: fit-content;
    }

    &__content {
      ${size('100%')};
      position: relative;
      border: ${theme.sizes.SIZE_1} dashed var(--SHADE_1_2);
      border-radius: ${theme.sizes.SIZE_8};
      display: ${(props) => (props.visible ? 'flex' : 'none !important')};
      padding: ${theme.sizes.SIZE_40};
      ${flex('column', 'center', 'center')};

      &__img {
        ${position(
          'absolute',
          `top ${theme.sizes.SIZE_10} left ${theme.sizes.SIZE_16}`
        )};
      }

      &__text {
        font-weight: 500;
        margin-bottom: ${theme.sizes.SIZE_20};
        color: var(--SHADE_1_2);
      }
    }

    .ReactCrop__crop-selection {
      box-shadow: 0 0 0 9999em rgb(0 0 0 / 80%);
    }

    .ReactCrop__image {
      max-width: ${pxToRem(theme.sizes.SIZE_504)};
      max-height: ${pxToRem(theme.sizes.SIZE_504)};

      ${mobile(css`
        max-width: 100%;
        max-height: ${pxToRem(theme.sizes.SIZE_304)};
      `)}

      ${mobileLandscape(css`
        max-width: 100%;
        max-height: ${pxToRem(theme.sizes.SIZE_304)};
      `)};
    }
  }

  .image-preview {
    &__spinner-container {
      ${flex('row', 'center', 'center')};
    }

    &__spinner-container,
    &__image {
      border-radius: 50%;
      ${size(theme.sizes.SIZE_152)};
      ${elevate_s};

      min-width: ${pxToRem(theme.sizes.SIZE_152)};

      ${phablet(css`
        margin-right: unset;
        margin-bottom: ${theme.sizes.SIZE_32};
      `)};

      &--community {
        border-radius: ${theme.sizes.SIZE_32};
      }
    }

    &__buttons {
      ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};
      ${flex('column', 'center', 'flex-start')};
    }
  }

  &.image-preview {
    ${flex('row')};
    ${marginChildren(`0 ${theme.sizes.SIZE_32} 0 0`)};

    ${phablet(css`
      flex-direction: column;
    `)};
  }

  .community-description {
    width: 100%;
    max-width: ${pxToRem(theme.sizes.SIZE_632)};
    margin: ${theme.sizes.SIZE_32} 0 ${theme.sizes.SIZE_48};

    @media (max-width: ${theme.breakAtMaxWidth}px) {
      max-width: unset;
    }

    > .textarea__container {
      width: 100%;
    }
  }

  .form {
    &__fields {
      ${flexGap(theme.sizes.SIZE_32, 'column')};

      /* for case when there is no label on input we want bigger margin */
      .input {
        > div:first-child {
          margin-top: ${theme.sizes.SIZE_16};
        }
      }
      .input,
      .wcl-switch {
        max-width: ${pxToRem(theme.sizes.SIZE_320)};
      }

      ${phablet(css`
        max-width: unset;
      `)};
    }
  }

  .update-address-form {
    ${flex('row')}

    fieldset {
      min-width: ${theme.sizes.SIZE_208};
    }

    ${laptop(css`
      img {
        transform: scale(0.6);
      }
    `)};

    ${phablet(css`
      img {
        display: none;
      }
    `)};
  }

  .contact-details-form {
    ${flexGap(theme.sizes.SIZE_32, 'column')};

    max-width: ${theme.sizes.SIZE_320};

    &__desc {
      color: var(--SHADE_1_2);
    }

    &__curr-email {
      font-size: ${theme.fontSizes.FONT_18};
      color: var(--SHADE_1_1);

      div {
        margin-bottom: ${theme.fontSizes.FONT_12};
      }
    }
  }

  .custom-input-form {
    &__phone-preview {
      ${size(theme.sizes.SIZE_288)};
      ${flex('column', 'center', 'flex-start')};
      ${position('absolute', `top ${pxToRem('-111px')}  right 0`)};

      flex: 0 0 ${pxToRem(theme.sizes.SIZE_320)};
      border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);
      border-left: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);
      border-bottom-left-radius: ${theme.sizes.SIZE_8};

      ${laptop(
        css`
          margin: ${theme.sizes.SIZE_32} auto 0;
          border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);
          border-radius: ${theme.sizes.SIZE_8};
          position: unset;
        `
      )}

      svg {
        ${elevate_xxl};
        border-top-left-radius: ${theme.sizes.SIZE_20};
        border-top-right-radius: ${theme.sizes.SIZE_20};
      }

      &__label {
        color: var(--SHADE_1_1);
        width: 100%;
        text-align: center;
        margin-bottom: ${theme.sizes.SIZE_12};
      }
    }
  }

  &.update-profile-form {
    form {
      display: grid;
      grid-template-columns: 1fr 1fr;

      fieldset {
        flex: 1 1 100%;
      }
    }

    ${phablet(
      css`
        form {
          ${flex('column')};

          .data-usage-consents {
            margin-top: ${theme.sizes.SIZE_32};
          }
        }
      `
    )}
  }

  .data-usage-consents {
    background-color: var(--BACKGROUND_2_1);
    padding: ${theme.sizes.SIZE_32};
    border-radius: ${theme.sizes.SIZE_8};
  }
`;

export default {
  TemplateGrid,
  AuthGrid,
  SettingsForm,
};
